<template>
    <div class="resume">
        <el-page-header @back="goBack" content="简历详情" >
        </el-page-header>  
        <div class="topBtn">
            <span>按岗位查询：</span>
            <el-input v-model="jobName" placeholder="请输入岗位名称" 
            style="width:180px" size="medium" clearable></el-input>
            <span>按姓名查询：</span>
            <el-input v-model="stuName" placeholder="请输入姓名" 
            style="width:180px" size="medium" clearable></el-input>
            <span>按学历查询：</span>
            <el-select
              v-model="edu"
              placeholder="请选择学历" 
              clearable
              size="medium"
              style="width:120px"
              >
                <el-option
                v-for="item in options"
                :key="item"
                :label="item"
                :value="item">
                </el-option>
            </el-select>
            <span>按性别查询：</span>
            <el-radio v-model="sex" label="1">男</el-radio>
            <el-radio v-model="sex" label="0">女</el-radio>
            <el-button type="primary" size="small"
            icon="el-icon-search" 
            style="margin-left:10px" @click="search">搜索</el-button>
            <el-button  size="small" style="margin-left:10px" @click="clearSearch">清空搜索</el-button>
        </div>
        <el-table
            :data="tableData"
            border
            style="width: 100%">
            <el-table-column
            type="index"
            label="#序号"
            width="60">
            </el-table-column>
            <el-table-column
            width="180"
             :show-overflow-tooltip="true"
            label="应聘岗位">
                <template slot-scope="scope">
                    <span>{{scope.row.zpJob.jobName}}</span>
                </template>
            </el-table-column>
            <el-table-column
            :show-overflow-tooltip="true"
            label="姓名">
             <template slot-scope="scope">
                    <span>{{scope.row.zpResume.name}}</span>
                </template>
            </el-table-column>
            <el-table-column
            :show-overflow-tooltip="true"
            label="性别">
                <template slot-scope="scope">
                   <span>{{scope.row.zpResume.sex==0?'女':'男'}}</span>
                </template>
            </el-table-column>
             <el-table-column
            :show-overflow-tooltip="true"
            label="年龄(岁)">
                 <template slot-scope="scope">
                    <span>{{scope.row.zpResume.birthday | getAge()}}</span>
                </template>
            </el-table-column>
            <el-table-column
            :show-overflow-tooltip="true"
            label="学历">
                 <template slot-scope="scope">
                    <span>{{scope.row.zpResume.education}}</span>
                </template>
            </el-table-column>
            <el-table-column
            :show-overflow-tooltip="true"
            label="手机号码">
                 <template slot-scope="scope">
                    <span>{{scope.row.zpResume.phone}}</span>
                </template>
            </el-table-column>
            <el-table-column
            width="180"
             :show-overflow-tooltip="true"
            label="面试时间">
                <template slot-scope="scope">
                    <span>{{scope.row.interviewTime}}
                        <i v-show="scope.row.interviewTime"
                        @click="updateInterviewTime(scope.row.id,scope.row.interviewTime)"
                        title='修改面试时间' 
                        class="el-icon-edit"></i>
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="状态">
                <template slot-scope="scope">
                    <el-tag
                    :type="scope.row.state?scope.row.state==1?'warning':
                    scope.row.state==2?'info':'success'
                    :''"
                    disable-transitions>
                    {{scope.row.state?scope.row.state==1?'未处理':
                    scope.row.state==2?'不合适':'已邀约'
                    :''}}
                    </el-tag>       
                </template>      
            </el-table-column>
            <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                    <el-button
                    size="mini" 
                    type="danger"
                    :disabled="scope.row.state!=1"
                    @click="gomeet(scope.row.id)">
                    是否邀约
                    </el-button> 
                    <el-button
                    size="mini" 
                    type="primary"
                    @click="lookPersonResume(scope.row.zpResume)">
                    查看简历
                    </el-button>         
                </template>      
            </el-table-column>           
        </el-table>
        <paging v-show="tableData.length>0" :totalpage="totalpage" 
            @getInitList="getInitList" ref="pages" v-on:handle="handle"></paging>
        <el-dialog title="面试" :visible.sync="meetModel" width="40%" :closeOnClickModal="false">
            <el-form :model="form" >     
                <el-form-item label="是否面试：" :label-width="formLabelWidth">
                    <img class="must" src="../../assets/images/must.png"/>
                    <el-radio-group v-model="form.interviewType" >
                        <el-radio :label="1">线上面试</el-radio>
                        <el-radio :label="2">现场面试</el-radio>
                        <el-radio :label="7">不合适</el-radio>
                    </el-radio-group>
                </el-form-item> 
                <el-form-item label="面试时间：" :label-width="formLabelWidth"
                v-if="form.interviewType!=7">
                    <img class="must" src="../../assets/images/must.png"/>
                    <el-date-picker
                    v-model="form.interviewTime"
                    type="datetime"
                    placeholder="请选择面试时间"
                    value-format='yyyy-MM-dd HH:mm'
                    default-time="10:00:00">
                    </el-date-picker>
                    
                </el-form-item> 
                 <el-form-item label="面试须知：" 
                 v-if="form.interviewType!=7"
                 :label-width="formLabelWidth">
                    <el-input  
                    type="textarea"
                    rows="3"
                    v-model="form.interviewTypeDetail"  
                    :placeholder="form.interviewType==1?tip[0]:tip[1]"></el-input>
                </el-form-item> 
                   
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="meetModel = false" size="small">取 消</el-button>
                <el-button type="primary" @click="sureMeet" size="small">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="编辑面试时间" :visible.sync="updateTimeModel" width="40%" :closeOnClickModal="false">
            <el-form  >     
                <el-form-item label="面试时间：" :label-width="formLabelWidth">
                    <img class="must" src="../../assets/images/must.png"/>
                    <el-date-picker
                    style="width:100%"
                    v-model="InterviewTime"
                    type="datetime"
                    placeholder="请选择面试时间"
                    value-format='yyyy-MM-dd HH:mm'
                    default-time="10:00:00">
                    </el-date-picker>  
                </el-form-item>  
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="updateTimeModel = false" size="small">取 消</el-button>
                <el-button type="primary" @click="sureUpdateTime" size="small">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {   
    data() {
        return {
            jobName:'',
            stuName:'',
            sex:'',
            edu:'',
            options:['本科','专科','硕士'],
            tip:[
                "推荐使用平台一对一面试功能，如需使用第三方面试工具如：QQ、微信、腾讯会议，请填写相关信息",
                "请输入现场面试详细地址和联系人等信息"
            ],
            formLabelWidth:'100px',
            form:{},
            tableData:[],
            companyId:'',
            dcId:"" ,
            meetModel:false,
            choseId:'' ,
            totalpage:0 ,
            current:0,
            InterviewTime:'',
            updateTimeModel:false ,
            updateTimeId:''  
        }
    },
    methods: {
        //搜索
        search(){
            let str=''
            let {jobName,stuName,sex,edu}=this
            if(jobName){str+=`&jobName=${this.jobName}`}
            if(stuName){str+=`&stuName=${this.stuName}`}
            if(sex){str+=`&sex=${this.sex}`}
            if(edu){str+=`&edu=${this.edu}`}
            let url=`/hr/resume/list?page=0&companyId=${this.companyId}&dcId=${this.dcId}${str}`;  
            this.$htp.get_(url).then((res)=>{
                this.tableData=res.data.rows; 
                this.totalpage=res.data.total 
                     
            }) 
            
        },
        //清空搜索
        clearSearch(){
            this.jobName=''
            this.stuName='',
            this.sex=''
            this.edu=''
            this.search()
        },
        handle(val){
            this.current=val  
        }, 
        //修改面试时间
        updateInterviewTime(id,time){
            this.updateTimeId=id
            this.updateTimeModel=!this.updateTimeModel
            this.InterviewTime=time
        },
        sureUpdateTime(){
            if(!this.InterviewTime){
                this.$allFun.getPromptBox('请选择面试时间')
                return
            }
            let obj={
                interviewTime:this.InterviewTime,
                id:this.updateTimeId
            }
            this.$htp.post_('/hr/resume/job/update',obj,true).then(()=>{
                this.getInitList(this.current)       
            }) 
            this.updateTimeModel=!this.updateTimeModel
        } ,
        sureMeet(){
            let obj={}
            obj.id=this.choseId
            if(this.form.interviewType==7){
                this.$htp.post_('/hr/resume/job/improper',obj,true).then(()=>{
                    this.getInitList(this.current)
                    this.meetModel=!this.meetModel        
                }) 
            }else{
                obj={...this.form}
                obj.id=this.choseId
                if(!obj.interviewTime){
                    this.$message({
                        type:"warning",
                        message:`请输入面试时间`
                    })
                    return false
                }
                let url=`/hr/resume/job/add`;      
                this.$htp.post_(url,obj,true).then(()=>{
                    this.getInitList(this.current)
                    this.meetModel=!this.meetModel        
                }) 
            }
            
        },
        gomeet(id){
           this.form={interviewType:1}
           this.choseId=id
           this.meetModel=!this.meetModel 
        },
        lookPersonResume(zpResume){
            sessionStorage.setItem('zpResume',JSON.stringify(zpResume))
             const { href } = this.$router.resolve({
                name: `resumePerson`
            });
            window.open(href, "_blank");
        },
        goBack(){
            this.$router.push({path:"/resume"})
        },
        getInitList(current=0){
            let url=`/hr/resume/list?page=${current}&companyId=${this.companyId}&dcId=${this.dcId}`;      
            this.$htp.get_(url).then((res)=>{
                this.tableData=res.data.rows; 
                this.totalpage=res.data.total          
            }) 
        },   
    },
    mounted(){
        this.companyId=sessionStorage.getItem('id')
        this.dcId=this.$route.query.id
        this.getInitList()    
    }
}
</script>
<style lang="scss" scoped>
@import '../../assets/css/common.scss';
.el-form-item{
    position: relative;
    .must{
        width: 20px;
        height: 20px;
        position: absolute;
        top: 10px;
        left: -100px;
    }
}
.el-icon-edit{
    margin-left: 10px;
    cursor: pointer;
}
.el-icon-edit:hover{
    color: #409eff;
}

.topBtn{
    margin-top: 20px;
    font-size: 14px;
    span{
        margin-left: 10px;
    }
}


</style>
